/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { SendLoginLink } from '../models';
import { SendLoginLinkFromJSON, SendLoginLinkToJSON } from '../models';
import * as runtime from '../runtime';

export interface SendLoginLinkRequest {
  body?: SendLoginLink;
}

/**
 *
 */
export class AuthApi extends runtime.BaseAPI {
  /**
   * Send a login link to the user email
   */
  async sendLoginLinkRaw(
    requestParameters: SendLoginLinkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/consumer/auth/login-with-link`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: SendLoginLinkToJSON(requestParameters.body),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Send a login link to the user email
   */
  async sendLoginLink(
    requestParameters: SendLoginLinkRequest = {},
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.sendLoginLinkRaw(requestParameters, initOverrides);
  }
}
