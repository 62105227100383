import { ConnectedItem, DisconnectedItem, IntegrationId } from './utils/types';
import { useIntegration } from './utils/useIntegration';
import { TenantIcon } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { List } from '@clevergy/ui/components/List';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const IntegrationsPage: FC = () => {
  const { t } = useTranslation();
  const { connectedList, disconnectedList } = useIntegration();
  const hasConnectedList = connectedList.length > 0;
  const hasDisconnectedList = disconnectedList.length > 0;

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('integrations.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        {hasConnectedList && (
          <List>
            {connectedList.map((item: ConnectedItem) => (
              <ConnectedListItem key={item.id} item={item} />
            ))}
          </List>
        )}

        {hasDisconnectedList && (
          <>
            <List>
              {disconnectedList.map((item: DisconnectedItem) => (
                <DisconnectedListItem key={item.id} item={item} />
              ))}
            </List>
          </>
        )}
      </Wrapper>
    </Page>
  );
};

const IntegrationItemIcon: FC<{ id: IntegrationId }> = ({ id }) => {
  switch (id) {
    case IntegrationId.Alexa:
      return <TenantIcon name="Alexa" />;
    default:
      return <FontIcon name="share" />;
  }
};

const ConnectButton: FC<{ path: string }> = ({ path }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const isExternalLink = path.startsWith('http');

  if (isExternalLink) {
    return (
      <Button as="a" href={path}>
        {t('integrations.buttonConnect')}
      </Button>
    );
  }

  return (
    <Button onClick={() => navigate(path)}>
      {t('integrations.buttonConnect')}
    </Button>
  );
};

const ConnectedListItem: FC<{ item: ConnectedItem }> = ({ item }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <List.Item
      leftIcon={<IntegrationItemIcon id={item.id} />}
      rightIcon={<FontIcon name="chevron_right" />}
      onClick={() => navigate(item.pathForDisconnect)}
    >
      {t(item.title)}
    </List.Item>
  );
};

const DisconnectedListItem: FC<{ item: DisconnectedItem }> = ({ item }) => {
  const { t } = useTranslation();
  return (
    <List.Item
      leftIcon={<IntegrationItemIcon id={item.id} />}
      rightIcon={<ConnectButton path={item.pathForConnect} />}
    >
      {t(item.title)}
    </List.Item>
  );
};
