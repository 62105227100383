/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface NotificationTrack
 */
export interface NotificationTrack {
  /**
   * Communication identifier
   * @type {string}
   * @memberof NotificationTrack
   */
  communicationId: string;
  /**
   * Device token
   * @type {string}
   * @memberof NotificationTrack
   */
  deviceToken: string;
}

/**
 * Check if a given object implements the NotificationTrack interface.
 */
export function instanceOfNotificationTrack(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'communicationId' in value;
  isInstance = isInstance && 'deviceToken' in value;

  return isInstance;
}

export function NotificationTrackFromJSON(json: any): NotificationTrack {
  return NotificationTrackFromJSONTyped(json, false);
}

export function NotificationTrackFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): NotificationTrack {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    communicationId: json['communicationId'],
    deviceToken: json['deviceToken'],
  };
}

export function NotificationTrackToJSON(value?: NotificationTrack | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    communicationId: value.communicationId,
    deviceToken: value.deviceToken,
  };
}
