import { TenantPageIntroWithLogo } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { Page } from 'components/Page';
import { Wrapper } from 'components/Wrapper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

export const NotFoundPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Page>
      <Wrapper>
        <div className="flex flex-col items-center justify-center gap-4 p-safe h-full">
          <TenantPageIntroWithLogo title={t('not-found.title')} />
          <Button onClick={() => navigate('/')} variant="filled">
            Entendido
          </Button>
        </div>
      </Wrapper>
    </Page>
  );
};
