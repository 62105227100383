import { UserSetUpUserStatusEnum } from '@clevergy/api-client';
import { TenantLoadingIndicator, useTenantSettings } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { ModuleHeader } from '@clevergy/ui/components/ModuleHeader';
import ComparisonModuleDraft from 'assets/illustrations/comparison-module-draft.svg?react';
import { AppAlerts } from 'components/AppAlerts';
import { ModuleCard } from 'components/ModuleCard';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { PullToRefresh } from 'components/PullToRefresh';
import { Wrapper } from 'components/Wrapper';
import { useAuthContext } from 'context/AuthContext';
import { useUserSetupContext } from 'context/UserSetupContext';
import { format } from 'date-fns';
import { useSelectedHouse } from 'hooks/useSelectedHouse';
import { useSmartMeterIntegration } from 'hooks/useSmartMeterIntegration';
import { FC } from 'react';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

const tooltipHouseComparison = `
<ul>
  <li>
    <strong>Hogares eficientes:</strong>
    consumo medio de hogares con
    características similares según tu configuración de la cuenta,
    teniendo en cuenta solo el 30% de los hogares con menor consumo.
  </li>
  <li>
    <strong>Hogares similares:</strong>
    consumo medio de hogares con características similares según tu configuración de la cuenta.
  </li>
  <li>
    <strong>Tus vecinos:</strong>
    consumo medio de hogares con el mismo código postal que tú.
  </li>
</ul>
`;

export const HomePage: FC = () => {
  const { token } = useAuthContext();
  const { userStatus, userInfo } = useUserSetupContext();
  const { selectedHouse } = useSelectedHouse();
  const { shouldSuggestSmartMeterIntegration } = useSmartMeterIntegration();

  const { hasEnergyPricesEnabled, tenantId } = useTenantSettings();
  const setHasSkippedSmartMeterIntegration = useAppConfigurationStore(
    (state) => state.setHasSkippedSmartMeterIntegration,
  );

  return (
    <Page>
      <PageHeader withHousePicker />
      <PullToRefresh />
      <Wrapper>
        {!token && <TenantLoadingIndicator />}

        {token && (
          <>
            <AppAlerts />

            {selectedHouse && (
              <clevergy-solar-real-time
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-power-chart
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-current-date={format(new Date(), 'yyyy-MM-dd')}
              />
            )}

            {selectedHouse && (
              <clevergy-solar-info
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-smart-devices
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {hasEnergyPricesEnabled && (
              <clevergy-energy-prices
                data-show-energy-price-surplus={
                  selectedHouse?.dataProviders?.solarInverter ? 'true' : 'false'
                }
                data-show-energy-price-list={
                  tenantId === 'selectra' ? 'true' : 'false'
                }
              />
            )}

            {selectedHouse && (
              <clevergy-consumption-cumulative
                data-token={token}
                data-house-id={selectedHouse.houseId}
                data-tooltip={''}
              />
            )}

            {selectedHouse && (
              <clevergy-invoice-viewer
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-virtual-wallet
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse && (
              <clevergy-virtual-battery
                data-token={token}
                data-house-id={selectedHouse.houseId}
              />
            )}

            {selectedHouse &&
              !userInfo.isCompany &&
              tenantId !== 'comunidad-solar' && (
                <clevergy-house-comparison
                  data-token={token}
                  data-house-id={selectedHouse.houseId}
                  data-view="race"
                  data-variant="advanced"
                  data-tooltip={tooltipHouseComparison}
                />
              )}

            {shouldSuggestSmartMeterIntegration &&
              tenantId !== 'comunidad-solar' && (
                <ModuleCard>
                  <ModuleHeader
                    title="Comparador de hogares"
                    subtitle="Compara tu consumo con el de otros hogares similares"
                  />
                  <div className="my-4">
                    <ComparisonModuleDraft />
                  </div>
                  {userStatus === UserSetUpUserStatusEnum.Initial && (
                    <Button
                      variant="filled"
                      fullWidth
                      onClick={() => setHasSkippedSmartMeterIntegration(false)}
                    >
                      Conectar mis datos de consumo
                    </Button>
                  )}
                </ModuleCard>
              )}
          </>
        )}
      </Wrapper>
    </Page>
  );
};
