import IntroSvg from '../assets/tutorial-intro.svg?react';
import { TrackedViewMount } from '@clevergy/amplitude/components/TrackedViewMount';
import { TenantLogo, useTenantSettings } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { Heading } from '@clevergy/ui/components/Heading';
import { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export type TutorialIntroProps = {
  onContinue: () => void;
};

export const TutorialIntro: FC<TutorialIntroProps> = ({ onContinue }) => {
  const { t } = useTranslation();
  const { tenantId } = useTenantSettings();

  return (
    <TrackedViewMount event={{ event_type: 'onboarding_start' }}>
      <div className="flex h-full flex-col gap-4">
        <div className="flex grow flex-col gap-4">
          <div>
            <TenantLogo type="small" className="h-10" />
          </div>
          <div className="max-w-[200px]">
            <Heading as="h1" colorScheme="neutral">
              {tenantId === 'comunidad-solar'
                ? 'El camino a la independencia energética'
                : t('tutorial.intro.title')}
              {}
            </Heading>
          </div>
          <div className="flex grow items-center">
            <IntroSvg width={`100%`} />
          </div>
        </div>
        <Button onClick={onContinue} testId="tutorial-intro-continue-button">
          <Trans>tutorial.intro.continue</Trans>
          <FontIcon name="arrow_forward_ios" />
        </Button>
      </div>
    </TrackedViewMount>
  );
};
