/* tslint:disable */

/* eslint-disable */

/**
 * Clevergy Portal Routes
 * Clevergy Portal Routes
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import type { NotificationTrack } from '../models';
import { NotificationTrackFromJSON, NotificationTrackToJSON } from '../models';
import * as runtime from '../runtime';

export interface TrackNotificationRequest {
  body: NotificationTrack;
}

/**
 *
 */
export class NotificationsApi extends runtime.BaseAPI {
  /**
   * Track notification
   */
  async trackNotificationRaw(
    requestParameters: TrackNotificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.body === null ||
      requestParameters.body === undefined
    ) {
      throw new runtime.RequiredError(
        'body',
        'Required parameter requestParameters.body was null or undefined when calling trackNotification.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    const response = await this.request(
      {
        path: `/api/consumer/notifications/track`,
        method: 'POST',
        headers: headerParameters,
        query: queryParameters,
        body: NotificationTrackToJSON(requestParameters.body),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Track notification
   */
  async trackNotification(
    requestParameters: TrackNotificationRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.trackNotificationRaw(requestParameters, initOverrides);
  }
}
