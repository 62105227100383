import { TrackedButton } from '@clevergy/amplitude/components/TrackedButton';
import { useTenantSettings } from '@clevergy/tenants';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { useNotificationsContext } from 'context/NotificationsContext';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

const ONE_SECOND_IN_MS = 1000;

export const OnBoardingNotificationsPage: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tenantSettings = useTenantSettings();

  const setHasAnsweredNotifications = useAppConfigurationStore(
    (state) => state.setHasAnsweredNotifications,
  );

  const { permissionsStatus, requestNotificationsPermission } =
    useNotificationsContext();

  const [notificationButtonClicked, setNotificationButtonClicked] =
    useState(false);

  const handleActivateNotifications = () => {
    requestNotificationsPermission();

    setTimeout(() => {
      setNotificationButtonClicked(true);
    }, ONE_SECOND_IN_MS);
  };

  const handleContinue = () => {
    setHasAnsweredNotifications(true);
    navigate('/');
  };

  const displayActiveNotifications =
    tenantSettings.hasNotificationsFeatureEnabled &&
    permissionsStatus &&
    permissionsStatus !== 'unavailable' &&
    !notificationButtonClicked;

  return (
    <Page>
      <PageHeader title={t('notifications.title') as string} />
      <Wrapper>
        <div className="flex flex-col gap-4 h-full">
          <p>{t('notifications.subtitle')}</p>
          <div className="flex flex-col items-center grow w-full">
            <img
              src="./illustrations/notifications.png"
              alt={t('notifications.imageAlt') as string}
            />
          </div>

          <TrackedButton
            as="a"
            fullWidth
            colorScheme="primary"
            variant="flat"
            onClick={handleContinue}
            event={{
              event_type: 'notification_skipped',
            }}
          >
            <>{t('notifications.skipStep')}</>
          </TrackedButton>

          {displayActiveNotifications ? (
            <TrackedButton
              fullWidth
              colorScheme="primary"
              onClick={handleActivateNotifications}
              event={{
                event_type: 'notification_activated',
              }}
            >
              <>{t('notifications.active')}</>
            </TrackedButton>
          ) : (
            <TrackedButton
              fullWidth
              colorScheme="primary"
              onClick={handleContinue}
              event={{
                event_type: 'notification_continue',
              }}
            >
              <>{t('notifications.continue')}</>
            </TrackedButton>
          )}
        </div>
      </Wrapper>
    </Page>
  );
};
