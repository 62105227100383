import { FC, ReactNode } from 'react';

export type AppLayoutProps = {
  children: ReactNode;
};

export const AppLayout: FC<AppLayoutProps> = (props) => {
  const { children } = props;
  return (
    <div className="absolute inset-0 flex flex-col justify-between h-full w-full min-h-full">
      {children}
    </div>
  );
};
