import { FC, ReactNode } from 'react';

export type ModuleCardProps = {
  children: ReactNode;
};

export const ModuleCard: FC<ModuleCardProps> = ({ children }) => {
  return (
    <div className="bg-clevergy-moduleContainerBackground shadow-moduleContainer rounded-moduleContainer p-moduleContainerPadding m-moduleContainerMargin">
      {children}
    </div>
  );
};
