import { getTenantSettingsFromGcpTenantId } from '@clevergy/tenants';
import { Button } from '@clevergy/ui/components/Button';
import { InputText } from '@clevergy/ui/components/InputText';
import { Prose } from '@clevergy/ui/components/Prose';
import { formatGcpTenantId } from '@clevergy/utils/format/formatGcpTenantId';
import Illustration from 'assets/illustrations/contact.svg?react';
import { Wrapper } from 'components/Wrapper';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

type SignupFormValues = {
  tenantCode: string;
};

export const TenantCodePage: FC = () => {
  const { t } = useTranslation();
  const { setTenantId } = useAppConfigurationStore();
  const { register, handleSubmit, setError, clearErrors, formState, setValue } =
    useForm<SignupFormValues>();

  const onSubmit = async (data: SignupFormValues) => {
    const tenantSettings = await getTenantSettingsFromGcpTenantId(
      data.tenantCode,
    );
    if (!tenantSettings) {
      setError('tenantCode', {
        type: 'tenantCode',
        message: t('tenantCodePage.form.code.invalid') as string,
      });
      return;
    }
    setTenantId(tenantSettings.tenantId);
  };

  return (
    <div className="flex flex-col min-h-full w-full items-center justify-center shrink-0">
      <div
        className="bg-clevergy-moduleContainerBackground flex flex-col h-full w-full overflow-hidden sm:my-moduleContainerPadding sm:shrink-0 sm:min-h-[600px] sm:h-auto sm:max-w-md
       sm:shadow-moduleContainer sm:rounded-moduleContainer"
      >
        <Wrapper>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 h-full justify-center p-safe"
          >
            <div className="flex justify-center">
              <Illustration />
            </div>
            <Prose>
              <p className="text-center">{t('tenantCodePage.title')}</p>
            </Prose>
            <InputText
              id="tenantCode"
              type="text"
              label={t('tenantCodePage.form.code.label') as string}
              placeholder={t('tenantCodePage.form.code.placeholder')}
              {...register('tenantCode', {
                required: t('tenantCodePage.form.code.required') as string,
                onChange: (e) =>
                  setValue('tenantCode', formatGcpTenantId(e.target.value)),
              })}
              error={formState.errors.tenantCode?.message as string}
            />
            <Button
              type="submit"
              variant="filled"
              fullWidth
              busy={formState.isSubmitting}
              disabled={formState.isSubmitting}
              onClick={() => clearErrors()}
              testId="tenant-code-submit-button"
            >
              {t('tenantCodePage.form.submit')}
            </Button>
          </form>
        </Wrapper>
      </div>
    </div>
  );
};
