import { useAuthContext } from 'context/AuthContext';
import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';

export const OnlyPublicRoute: FC = () => {
  const { authedUser } = useAuthContext();
  const location = useLocation();

  if (typeof authedUser === 'undefined') {
    return null;
  }

  if (authedUser) {
    return <Navigate to={`/`} state={{ from: location }} replace />;
  }

  return <Outlet />;
};
