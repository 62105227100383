import { SplashScreen } from '@capacitor/splash-screen';
import { AnalyticsProvider } from '@clevergy/amplitude/context/AnalyticsContext';
import { EventBus, EventBusContextProvider } from '@clevergy/event-bus';
import { ModalProvider } from '@clevergy/ui/context/ModalContext';
import { AppRoutes } from 'AppRoutes';
import { PageWithModules } from 'components//PageWithModules';
import { GoogleTagManager } from 'components/GoogleTagManager';
import { HouseSettingsBackupRestorer } from 'components/HouseSettingsBackupRestorer';
import { LastLogginTracker } from 'components/LastLogginTracker';
import { MultiTenantWrapper } from 'components/MultiTenantWrapper';
import { ApiProvider } from 'context/ApiContext';
import { AppUpdaterProvider } from 'context/AppUpdaterContext';
import { AuthProvider } from 'context/AuthContext';
import { NotificationsProvider } from 'context/NotificationsContext';
import { QueryProvider } from 'context/QueryContext';
import { TranslationsProvider } from 'context/TranslationsContext';
import { UserSetupProvider } from 'context/UserSetupContext';
import { AppLayout } from 'layouts/AppLayout';
import { AppAnalyticsListener } from 'listeners/AppAnalyticsListener';
import { AppUrlListener } from 'listeners/AppUrlListener';
import { AppTutorialModal } from 'pages/tutorial/AppTutorialModal';
import { FC, useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'styles/index.css';

const eventBus = new EventBus();

export const Root: FC = () => {
  // Hide the splash screen when app is ready
  useEffect(() => {
    SplashScreen.hide();
  });

  return (
    <AppLayout>
      <EventBusContextProvider eventBus={eventBus}>
        <QueryProvider>
          <TranslationsProvider>
            <ModalProvider modalRenderElement={document.body}>
              <AnalyticsProvider>
                <AppUpdaterProvider>
                  <AuthProvider>
                    <ApiProvider>
                      <UserSetupProvider>
                        <PageWithModules>
                          <MultiTenantWrapper>
                            <NotificationsProvider>
                              <LastLogginTracker />
                              <GoogleTagManager />
                              <AppUrlListener />
                              <AppAnalyticsListener />
                              <HouseSettingsBackupRestorer />
                              <AppTutorialModal />
                              <AppRoutes />
                            </NotificationsProvider>
                          </MultiTenantWrapper>
                        </PageWithModules>
                      </UserSetupProvider>
                    </ApiProvider>
                  </AuthProvider>
                </AppUpdaterProvider>
              </AnalyticsProvider>
            </ModalProvider>
          </TranslationsProvider>
        </QueryProvider>
      </EventBusContextProvider>
    </AppLayout>
  );
};

const router = createBrowserRouter([{ path: '*', element: <Root /> }]);

export const App = () => {
  return <RouterProvider router={router} />;
};
