/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 *
 * @export
 * @interface EnergyItemSmartDevicesInner
 */
export interface EnergyItemSmartDevicesInner {
  /**
   *
   * @type {string}
   * @memberof EnergyItemSmartDevicesInner
   */
  subtype: string;
  /**
   *
   * @type {string}
   * @memberof EnergyItemSmartDevicesInner
   */
  vendor: EnergyItemSmartDevicesInnerVendorEnum;
  /**
   *
   * @type {number}
   * @memberof EnergyItemSmartDevicesInner
   */
  energy: number;
}

/**
 * @export
 */
export const EnergyItemSmartDevicesInnerVendorEnum = {
  Shelly: 'SHELLY',
} as const;
export type EnergyItemSmartDevicesInnerVendorEnum =
  (typeof EnergyItemSmartDevicesInnerVendorEnum)[keyof typeof EnergyItemSmartDevicesInnerVendorEnum];

/**
 * Check if a given object implements the EnergyItemSmartDevicesInner interface.
 */
export function instanceOfEnergyItemSmartDevicesInner(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'subtype' in value;
  isInstance = isInstance && 'vendor' in value;
  isInstance = isInstance && 'energy' in value;

  return isInstance;
}

export function EnergyItemSmartDevicesInnerFromJSON(
  json: any,
): EnergyItemSmartDevicesInner {
  return EnergyItemSmartDevicesInnerFromJSONTyped(json, false);
}

export function EnergyItemSmartDevicesInnerFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItemSmartDevicesInner {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    subtype: json['subtype'],
    vendor: json['vendor'],
    energy: json['energy'],
  };
}

export function EnergyItemSmartDevicesInnerToJSON(
  value?: EnergyItemSmartDevicesInner | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    subtype: value.subtype,
    vendor: value.vendor,
    energy: value.energy,
  };
}
