/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';
import type { EnergyItemBattery } from './EnergyItemBattery';
import {
  EnergyItemBatteryFromJSON,
  EnergyItemBatteryFromJSONTyped,
  EnergyItemBatteryToJSON,
} from './EnergyItemBattery';
import type { EnergyItemEnergyCommunitiesInner } from './EnergyItemEnergyCommunitiesInner';
import {
  EnergyItemEnergyCommunitiesInnerFromJSON,
  EnergyItemEnergyCommunitiesInnerFromJSONTyped,
  EnergyItemEnergyCommunitiesInnerToJSON,
} from './EnergyItemEnergyCommunitiesInner';
import type { EnergyItemGrid } from './EnergyItemGrid';
import {
  EnergyItemGridFromJSON,
  EnergyItemGridFromJSONTyped,
  EnergyItemGridToJSON,
} from './EnergyItemGrid';
import type { EnergyItemHouse } from './EnergyItemHouse';
import {
  EnergyItemHouseFromJSON,
  EnergyItemHouseFromJSONTyped,
  EnergyItemHouseToJSON,
} from './EnergyItemHouse';
import type { EnergyItemSmartDevicesInner } from './EnergyItemSmartDevicesInner';
import {
  EnergyItemSmartDevicesInnerFromJSON,
  EnergyItemSmartDevicesInnerFromJSONTyped,
  EnergyItemSmartDevicesInnerToJSON,
} from './EnergyItemSmartDevicesInner';
import type { EnergyItemSolar } from './EnergyItemSolar';
import {
  EnergyItemSolarFromJSON,
  EnergyItemSolarFromJSONTyped,
  EnergyItemSolarToJSON,
} from './EnergyItemSolar';

/**
 *
 * @export
 * @interface EnergyItem
 */
export interface EnergyItem {
  /**
   *
   * @type {Date}
   * @memberof EnergyItem
   */
  date: Date;
  /**
   *
   * @type {EnergyItemSolar}
   * @memberof EnergyItem
   */
  solar?: EnergyItemSolar;
  /**
   *
   * @type {EnergyItemHouse}
   * @memberof EnergyItem
   */
  house?: EnergyItemHouse;
  /**
   *
   * @type {EnergyItemGrid}
   * @memberof EnergyItem
   */
  grid?: EnergyItemGrid;
  /**
   *
   * @type {EnergyItemBattery}
   * @memberof EnergyItem
   */
  battery?: EnergyItemBattery;
  /**
   *
   * @type {Array<EnergyItemEnergyCommunitiesInner>}
   * @memberof EnergyItem
   */
  energyCommunities?: Array<EnergyItemEnergyCommunitiesInner>;
  /**
   *
   * @type {Array<EnergyItemSmartDevicesInner>}
   * @memberof EnergyItem
   */
  smartDevices?: Array<EnergyItemSmartDevicesInner>;
}

/**
 * Check if a given object implements the EnergyItem interface.
 */
export function instanceOfEnergyItem(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'date' in value;

  return isInstance;
}

export function EnergyItemFromJSON(json: any): EnergyItem {
  return EnergyItemFromJSONTyped(json, false);
}

export function EnergyItemFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): EnergyItem {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    date: new Date(json['date']),
    solar: !exists(json, 'solar')
      ? undefined
      : EnergyItemSolarFromJSON(json['solar']),
    house: !exists(json, 'house')
      ? undefined
      : EnergyItemHouseFromJSON(json['house']),
    grid: !exists(json, 'grid')
      ? undefined
      : EnergyItemGridFromJSON(json['grid']),
    battery: !exists(json, 'battery')
      ? undefined
      : EnergyItemBatteryFromJSON(json['battery']),
    energyCommunities: !exists(json, 'energyCommunities')
      ? undefined
      : (json['energyCommunities'] as Array<any>).map(
          EnergyItemEnergyCommunitiesInnerFromJSON,
        ),
    smartDevices: !exists(json, 'smartDevices')
      ? undefined
      : (json['smartDevices'] as Array<any>).map(
          EnergyItemSmartDevicesInnerFromJSON,
        ),
  };
}

export function EnergyItemToJSON(value?: EnergyItem | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    date: value.date.toISOString(),
    solar: EnergyItemSolarToJSON(value.solar),
    house: EnergyItemHouseToJSON(value.house),
    grid: EnergyItemGridToJSON(value.grid),
    battery: EnergyItemBatteryToJSON(value.battery),
    energyCommunities:
      value.energyCommunities === undefined
        ? undefined
        : (value.energyCommunities as Array<any>).map(
            EnergyItemEnergyCommunitiesInnerToJSON,
          ),
    smartDevices:
      value.smartDevices === undefined
        ? undefined
        : (value.smartDevices as Array<any>).map(
            EnergyItemSmartDevicesInnerToJSON,
          ),
  };
}
