import { QueryKeys } from '@clevergy/shared/constants/queryKeys';
import {
  TenantSettingsProvider,
  getDefaultMultiTenantSettings,
  getTenantSettingsFromBrowserHostname,
  getTenantSettingsFromGcpTenantId,
  getTenantSettingsFromHostname,
  getTenantSettingsFromTenantId,
} from '@clevergy/tenants';
import { LoadingIndicator } from '@clevergy/ui/components/LoadingIndicator';
import { getGcpTenantIdFromToken } from '@clevergy/utils/jwt/getGcpTenantIdFromToken';
import { useQuery } from '@tanstack/react-query';
import { TenantPage } from 'components/TenantPage';
import { useAuthContext } from 'context/AuthContext';
import { TenantCodePage } from 'pages/auth/TenantCodePage';
import { FC, ReactNode, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppConfigurationStore } from 'stores/appConfigurationStore';

const forcedTenantDomain = import.meta.env.VITE_TENANT_DOMAIN;

export type MultiTenantWrapperProps = {
  children: ReactNode;
};

export const MultiTenantWrapper: FC<MultiTenantWrapperProps> = ({
  children,
}) => {
  const {
    setAuthTenantGcpId,
    setAuthLanguageCode,
    setIsEnabledEmailVerificationPulling,
    token,
  } = useAuthContext();
  const [searchParams] = useSearchParams();
  const tokenFromUrl = searchParams.get('token');

  const tenantIdFromMultiTenant = useAppConfigurationStore(
    (state) => state.tenantId,
  );

  const setTenantIdFromMultiTenant = useAppConfigurationStore(
    (state) => state.setTenantId,
  );

  const tokenForGetTenantSettings = tokenFromUrl || token;
  const gcpTenantIdFromToken = tokenForGetTenantSettings
    ? getGcpTenantIdFromToken(tokenForGetTenantSettings)
    : undefined;

  const tenantSettingsFromTokenQuery = useQuery({
    queryKey: [QueryKeys.TENANT_SETTINGS_FROM_TOKEN, { gcpTenantIdFromToken }],
    queryFn: () =>
      gcpTenantIdFromToken
        ? getTenantSettingsFromGcpTenantId(gcpTenantIdFromToken)
        : Promise.resolve(null),
  });

  const tenantSettingsFromDomainQuery = useQuery({
    queryKey: [QueryKeys.TENANT_SETTINGS_FROM_DOMAIN, { forcedTenantDomain }],
    queryFn: () =>
      forcedTenantDomain
        ? getTenantSettingsFromHostname(forcedTenantDomain)
        : getTenantSettingsFromBrowserHostname(),
  });

  const tenantSettingsFromStoreQuery = useQuery({
    queryKey: [
      QueryKeys.TENANT_SETTINGS_FROM_STORE,
      { tenantIdFromMultiTenant },
    ],
    queryFn: () =>
      tenantIdFromMultiTenant
        ? getTenantSettingsFromTenantId(tenantIdFromMultiTenant)
        : null,
  });

  const defaultMultiTenantSettingsQuery = useQuery({
    queryKey: [QueryKeys.DEFAULT_MULTI_TENANT_SETTINGS],
    queryFn: () => getDefaultMultiTenantSettings(),
  });

  // Compute tenant settings
  const resolvedTenantSettings = useMemo(
    () =>
      tenantSettingsFromTokenQuery.data ||
      tenantSettingsFromDomainQuery.data ||
      tenantSettingsFromStoreQuery.data,
    [
      tenantSettingsFromTokenQuery.data,
      tenantSettingsFromDomainQuery.data,
      tenantSettingsFromStoreQuery.data,
    ],
  );

  const isLoading = useMemo(
    () =>
      tenantSettingsFromTokenQuery.isPending ||
      tenantSettingsFromDomainQuery.isPending ||
      tenantSettingsFromStoreQuery.isPending ||
      defaultMultiTenantSettingsQuery.isPending,
    [
      tenantSettingsFromTokenQuery.isPending,
      tenantSettingsFromDomainQuery.isPending,
      tenantSettingsFromStoreQuery.isPending,
      defaultMultiTenantSettingsQuery.isPending,
    ],
  );

  // Set tenant id in auth context
  useEffect(() => {
    if (resolvedTenantSettings?.tenantGcpId) {
      setAuthTenantGcpId(resolvedTenantSettings.tenantGcpId);
      setTenantIdFromMultiTenant(resolvedTenantSettings.tenantId);
    }
  }, [setAuthTenantGcpId, resolvedTenantSettings, setTenantIdFromMultiTenant]);

  // Set language code in auth context
  useEffect(() => {
    if (resolvedTenantSettings?.languageCode) {
      setAuthLanguageCode(resolvedTenantSettings.languageCode);
    }
  }, [setAuthLanguageCode, resolvedTenantSettings]);

  // Set emailVerificationPulling if tenant has email verification enabled
  useEffect(() => {
    setIsEnabledEmailVerificationPulling(
      Boolean(resolvedTenantSettings?.requiresEmailVerification),
    );
  }, [resolvedTenantSettings, setIsEnabledEmailVerificationPulling]);

  if (isLoading) {
    return <LoadingIndicator fullScreen="transparent" />;
  }

  return (
    <>
      {!resolvedTenantSettings && defaultMultiTenantSettingsQuery.data && (
        <TenantSettingsProvider
          tenantSettings={defaultMultiTenantSettingsQuery.data}
        >
          <TenantPage>
            <TenantCodePage />
          </TenantPage>
        </TenantSettingsProvider>
      )}
      {resolvedTenantSettings && (
        <TenantSettingsProvider tenantSettings={resolvedTenantSettings}>
          <TenantPage>{children}</TenantPage>
        </TenantSettingsProvider>
      )}
    </>
  );
};
