import { useTenantSettings } from '@clevergy/tenants';
import { TenantLogo } from '@clevergy/tenants';
import { FontIcon } from '@clevergy/ui/components/FontIcon';
import { getWhatsappUrl } from '@clevergy/utils/urls/getWhatsappUrl';
import { Page } from 'components/Page';
import { PageHeader } from 'components/PageHeader';
import { Wrapper } from 'components/Wrapper';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const ContactPage: FC = () => {
  const { t } = useTranslation();

  const { contactInfo } = useTenantSettings();

  const items = useMemo(() => {
    const list: {
      id: string;
      key: string;
      icon: string;
      value: string;
      text: string;
    }[] = [];

    if (contactInfo?.email) {
      list.push({
        id: 'email',
        key: t('contact.email.key'),
        icon: 'email',
        value: '/support',
        text: contactInfo?.email,
      });
    }

    if (contactInfo?.whatsappNumber) {
      list.push({
        id: 'whatsapp',
        key: t('contact.whatsApp.key'),
        icon: 'phone',
        value: getWhatsappUrl({
          phone: contactInfo?.whatsappNumber,
          text: t('contact.whatsApp.message') as string,
        }),
        text: contactInfo?.whatsappNumber,
      });
    }

    if (contactInfo?.phoneNumber) {
      const phones = Array.isArray(contactInfo.phoneNumber)
        ? contactInfo.phoneNumber
        : [contactInfo.phoneNumber];

      phones.forEach((phone, index, arr) => {
        list.unshift({
          id: `phone-${index}`,
          key: t('contact.phone.key', {
            index: arr.length > 1 ? index + 1 : '',
          }),
          icon: 'phone',
          value: `tel:${phone}`,
          text: phone,
        });
      });
    }

    return list;
  }, [contactInfo, t]);

  return (
    <Page navbarLayoutContentMobileBackground="white">
      <PageHeader
        backButton
        title={t('contact.title') as string}
        hideTitleAndBackButtonForNonMobile
      />

      <Wrapper>
        <div className="flex flex-col items-center gap-4 text-center p-4">
          <div>
            <TenantLogo width={160} />
          </div>
          <p>{t('contact.description')}</p>
        </div>

        <ul>
          {items.map((item) => {
            return (
              <li
                key={item.id}
                className="flex w-full flex-wrap justify-between gap-4 rounded-none border-b border-gray-200 px-2 py-4 pr-2"
              >
                <div className="flex items-center gap-2 text-left">
                  <span className="text-clevergy-text">
                    <FontIcon name={item.icon} />
                  </span>
                  {item.key}
                </div>
                <div className="text-clevergy-primary underline truncate text-right">
                  <Link to={item.value}>{item.text}</Link>
                </div>
              </li>
            );
          })}
        </ul>
      </Wrapper>
    </Page>
  );
};
