/* tslint:disable */

/* eslint-disable */

/**
 * clevergy-api
 * Clevergy API
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { exists, mapValues } from '../runtime';

/**
 * Detail of invoice
 * @export
 * @interface InteractiveHouseInvoice
 */
export interface InteractiveHouseInvoice {
  /**
   *
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  houseId: string;
  /**
   * Start date of the invoicing period
   * @type {Date}
   * @memberof InteractiveHouseInvoice
   */
  startDate: Date;
  /**
   * End date of the invoicing period
   * @type {Date}
   * @memberof InteractiveHouseInvoice
   */
  endDate: Date;
  /**
   * Energy consumption 1st period
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyConsumptionP1?: number;
  /**
   * Energy consumption 2nd period
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyConsumptionP2?: number;
  /**
   * Energy consumption 3rd period
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyConsumptionP3?: number;
  /**
   * Total consumption of invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalEnergyConsumption?: number;
  /**
   * Total cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalCost: number;
  /**
   * Energy cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  energyCost?: number;
  /**
   * Power cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  powerCost?: number;
  /**
   * Other costs of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  otherCost?: number;
  /**
   * Discounts applied to the invoice. Negative values means discounts.
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  discountCost?: number;
  /**
   * Tax cost of the invoice
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  taxCost?: number;
  /**
   * URL to download the invoice file
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  urlFile?: string;
  /**
   * Cost of the service
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  serviceCost?: number;
  /**
   * Tax applied to invoice in percentage
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  tax?: number;
  /**
   * Cost of self consumption
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  selfConsumptionCost?: number;
  /**
   * Discounts applied to the invoice cominf from the client's wallet. Negative values means discounts.
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  virtualWallet?: number;
  /**
   * Difference in energy consumption between an invoice and the previous one
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalEnergyConsumptionDiff?: number;
  /**
   * Difference in percentage in energy consumption between an invoice and the previous one
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalEnergyConsumptionDiffPercentage?: number;
  /**
   * Difference in monetary cost between an invoice and the previous one
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  totalCostDiff?: number;
  /**
   * Id of the invoice previous to this interactive invoice
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  previousInvoiceId?: string;
  /**
   * Id of the invoice next to this interactive invoice
   * @type {string}
   * @memberof InteractiveHouseInvoice
   */
  nextInvoiceId?: string;
  /**
   * Rounded sum of taxCost and otherCost
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  taxesAndOthersCost?: number;
  /**
   * Rounded sum of taxCost, otherCost and serviceCost
   * @type {number}
   * @memberof InteractiveHouseInvoice
   */
  taxesAndOthersAndServicesCost?: number;
}

/**
 * Check if a given object implements the InteractiveHouseInvoice interface.
 */
export function instanceOfInteractiveHouseInvoice(value: object): boolean {
  let isInstance = true;
  isInstance = isInstance && 'houseId' in value;
  isInstance = isInstance && 'startDate' in value;
  isInstance = isInstance && 'endDate' in value;
  isInstance = isInstance && 'totalCost' in value;

  return isInstance;
}

export function InteractiveHouseInvoiceFromJSON(
  json: any,
): InteractiveHouseInvoice {
  return InteractiveHouseInvoiceFromJSONTyped(json, false);
}

export function InteractiveHouseInvoiceFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean,
): InteractiveHouseInvoice {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    houseId: json['houseId'],
    startDate: new Date(json['startDate']),
    endDate: new Date(json['endDate']),
    energyConsumptionP1: !exists(json, 'energyConsumptionP1')
      ? undefined
      : json['energyConsumptionP1'],
    energyConsumptionP2: !exists(json, 'energyConsumptionP2')
      ? undefined
      : json['energyConsumptionP2'],
    energyConsumptionP3: !exists(json, 'energyConsumptionP3')
      ? undefined
      : json['energyConsumptionP3'],
    totalEnergyConsumption: !exists(json, 'totalEnergyConsumption')
      ? undefined
      : json['totalEnergyConsumption'],
    totalCost: json['totalCost'],
    energyCost: !exists(json, 'energyCost') ? undefined : json['energyCost'],
    powerCost: !exists(json, 'powerCost') ? undefined : json['powerCost'],
    otherCost: !exists(json, 'otherCost') ? undefined : json['otherCost'],
    discountCost: !exists(json, 'discountCost')
      ? undefined
      : json['discountCost'],
    taxCost: !exists(json, 'taxCost') ? undefined : json['taxCost'],
    urlFile: !exists(json, 'urlFile') ? undefined : json['urlFile'],
    serviceCost: !exists(json, 'serviceCost') ? undefined : json['serviceCost'],
    tax: !exists(json, 'tax') ? undefined : json['tax'],
    selfConsumptionCost: !exists(json, 'selfConsumptionCost')
      ? undefined
      : json['selfConsumptionCost'],
    virtualWallet: !exists(json, 'virtualWallet')
      ? undefined
      : json['virtualWallet'],
    totalEnergyConsumptionDiff: !exists(json, 'totalEnergyConsumptionDiff')
      ? undefined
      : json['totalEnergyConsumptionDiff'],
    totalEnergyConsumptionDiffPercentage: !exists(
      json,
      'totalEnergyConsumptionDiffPercentage',
    )
      ? undefined
      : json['totalEnergyConsumptionDiffPercentage'],
    totalCostDiff: !exists(json, 'totalCostDiff')
      ? undefined
      : json['totalCostDiff'],
    previousInvoiceId: !exists(json, 'previousInvoiceId')
      ? undefined
      : json['previousInvoiceId'],
    nextInvoiceId: !exists(json, 'nextInvoiceId')
      ? undefined
      : json['nextInvoiceId'],
    taxesAndOthersCost: !exists(json, 'taxesAndOthersCost')
      ? undefined
      : json['taxesAndOthersCost'],
    taxesAndOthersAndServicesCost: !exists(
      json,
      'taxesAndOthersAndServicesCost',
    )
      ? undefined
      : json['taxesAndOthersAndServicesCost'],
  };
}

export function InteractiveHouseInvoiceToJSON(
  value?: InteractiveHouseInvoice | null,
): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    houseId: value.houseId,
    startDate: value.startDate.toISOString().substr(0, 10),
    endDate: value.endDate.toISOString().substr(0, 10),
    energyConsumptionP1: value.energyConsumptionP1,
    energyConsumptionP2: value.energyConsumptionP2,
    energyConsumptionP3: value.energyConsumptionP3,
    totalEnergyConsumption: value.totalEnergyConsumption,
    totalCost: value.totalCost,
    energyCost: value.energyCost,
    powerCost: value.powerCost,
    otherCost: value.otherCost,
    discountCost: value.discountCost,
    taxCost: value.taxCost,
    urlFile: value.urlFile,
    serviceCost: value.serviceCost,
    tax: value.tax,
    selfConsumptionCost: value.selfConsumptionCost,
    virtualWallet: value.virtualWallet,
    totalEnergyConsumptionDiff: value.totalEnergyConsumptionDiff,
    totalEnergyConsumptionDiffPercentage:
      value.totalEnergyConsumptionDiffPercentage,
    totalCostDiff: value.totalCostDiff,
    previousInvoiceId: value.previousInvoiceId,
    nextInvoiceId: value.nextInvoiceId,
    taxesAndOthersCost: value.taxesAndOthersCost,
    taxesAndOthersAndServicesCost: value.taxesAndOthersAndServicesCost,
  };
}
